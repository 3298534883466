import Vue from 'vue';
import Router from 'vue-router';
import LoginComponent from '@/components/Login.vue';
import store from '@/store/index';

Vue.use(Router);

let isRedirected = false;

async function ifNotAuthenticated(to, from, next) {
  if (!store.getters['login/getIsUserAuthenticated']) {
    if (!isRedirected) {
      sessionStorage.setItem('redirectUrl', window.location.pathname);
    } else {
      sessionStorage.removeItem('redirectUrl');
    }
    next();
    return;
  }
  next('/');
}

async function ifAuthenticated(to, from, next) {
  if (store.getters['login/getIsUserAuthenticated']) {
    const redirectUrl = sessionStorage.getItem('redirectUrl');
    if (!isRedirected && redirectUrl !== null && redirectUrl !== '/' && redirectUrl !== '/login') {
      sessionStorage.removeItem('redirectUrl');
      isRedirected = true;
      next(redirectUrl);
    } else {
      next();
    }
    return;
  }
  next('/login');
}

function checkAuthorization(to, from, next) {
  if (to.meta && to.meta.requiredRole) {
    const isAdmin = store.getters['login/getIsAdmin'];
    if (isAdmin) {
      // if user has the required role
      next();
    } else {
      // if user doesn't have the required role then
      // navigate to home page
      next('/');
    }
  } else {
    next();
  }
}

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/login',
      name: 'login',
      component: LoginComponent,
      beforeEnter: ifNotAuthenticated,
    },
    {
      path: '/',
      component: () => import(/* webpackChunkName: 'home' */ '@/components/Default.vue'),
      beforeEnter: ifAuthenticated,
      children: [
        {
          name: 'home',
          path: '',
          component: () => import(/* webpackChunkName: 'dahsboard' */ '@/components/Dashboard.vue'),
          // beforeEnter: checkAuthorization,
          // meta: {
          //    requiredRole: 'GLOBALADMIN'
          // },
        },
        {
          name: 'form',
          path: 'form/:formId/:submissionId?',
          props: true,
          component: () => import(/* webpackChunkName: 'form' */ '@/components/Form.vue'),
        },
        {
          name: 'liveshare',
          path: 'liveshare/:formId/sessionId/:sessionId',
          props: true,
          component: () => import(/* webpackChunkName: 'form' */ '@/components/LiveShareForm.vue'),
        },
        {
          name: 'updateform',
          path: 'updateform/:formId/:mlProjectId',
          props: true,
          component: () => import(/* webpackChunkName: 'updateform' */ '@/components/UpdateForm.vue'),
        },
        {
          name: 'admin',
          path: 'admin',
          component: () => import(/* webpackChunkName: 'admin' */ '@/components/Admin.vue'),
          beforeEnter: checkAuthorization,
          meta: {
            requiredRole: 'ADMIN',
          },
        },
        {
          name: 'settings',
          path: 'settings',
          component: () => import(/* webpackChunkName: 'settings' */ '@/components/Settings.vue'),
          beforeEnter: checkAuthorization,
          meta: {
            requiredRole: 'ADMIN',
          },
        },
        {
          path: '*',
          component: () => import('@/views/404.vue'),
        },
      ],
    },
  ],
});
