import Api from './Api';

const endPoint = 'graph';

const getTeams = (team) => Api.get(`${endPoint}/getteams`, {
  params: {
    teamFilter: team,
  },
});

const getChannels = (teamId) => Api.get(`${endPoint}/getteamchannels`, {
  params: {
    teamId,
  },
});

const getUserProfilePhoto = () => Api.get(`${endPoint}/getuserprofilephoto`);

export {
  getTeams,
  getChannels,
  getUserProfilePhoto,
};
