import { getSettings, saveSettings } from '../../services/settingsService';
import { getTeams, getChannels } from '../../services/graphService';
import { syncChoices } from '../../services/mavenbridgeService';
import { syncProperties } from '../../services/hubSpotGatewayService';

const getters = {
  getSettings(state) {
    return state.settings;
  },
  getTeams(state) {
    return state.teams;
  },
  getChannels(state) {
    return state.channels;
  },
  getIsReviewRequired: (state) => {
    if (state?.settings) {
      const settings = state.settings?.filter((setting) => setting.name === 'ReviewRequired');
      return settings?.length > 0 ? settings[0].value.name.trim() === 'true' : false;
    }
    return false;
  },
};

const actions = {
  getSettings({ commit }) {
    return new Promise((resolve, reject) => {
      getSettings().then((response) => {
        commit('setSettings', response.data.data);
        resolve();
      })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getTeams({ commit }, team) {
    return new Promise((resolve, reject) => {
      getTeams(team).then((response) => {
        commit('setTeams', response.data.data);
        resolve();
      })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getChannels({ commit }, team) {
    return new Promise((resolve, reject) => {
      getChannels(team).then((response) => {
        commit('setChannels', response.data.data);
        resolve();
      })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // eslint-disable-next-line no-unused-vars
  saveSettings({ commit }, settings) {
    return new Promise((resolve, reject) => {
      saveSettings(settings).then(() => {
        resolve();
      })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // eslint-disable-next-line no-unused-vars
  async syncChoices({ commit }) {
    const response = await syncChoices();
    return response.data.data;
  },
  // eslint-disable-next-line no-unused-vars
  async syncProperties({ commit }) {
    const response = await syncProperties();
    return response.data.data;
  },
};

const mutations = {
  setSettings(state, response) {
    state.settings = response;
  },
  setTeams(state, response) {
    state.teams = response;
  },
  setChannels(state, response) {
    state.channels = response;
  },
};

const state = {
  settings: [],
  teams: {},
  channels: {},
};

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
