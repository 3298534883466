<template>
  <div class="widget-notification-icon">
    <div style="text-align: right">
      <div @click="show_data = !show_data">
        <div class="anime-icon"></div>
      </div>
    </div>
    <div class="widget-data" :class="show_data ? 'show' : ''">
      <component
        :is="widget_data.ChartType"
        :series="widget_data.Data.Series"
        :options="widget_data.Data.ChartOptions"
      ></component>
    </div>
  </div>
</template>

<script type=" text/javascript">
import { mapState } from 'vuex';
import BarChart from '@/components/widgets/BarChart';
import Donut from '@/components/widgets/Donut';
import AreaChart from '@/components/widgets/AreaChart';
import TreeMap from '@/components/widgets/TreeMap';
import TagChart from '@/components/widgets/TagChart';

export default {
  name: 'Widget',
  components: {
    AreaChart, BarChart, Donut, TreeMap, TagChart,
  },
  computed: {
    ...mapState({
      widget_data: (state) => state.form.widgetData,
      show_widget: (state) => state.form.showWidget,
    }),
  },
  data() {
    return {
      show_data: false,
    };
  },
};
</script>
<style scoped lang="scss">
.widget-notification-icon {
  position: absolute;
  top: 10%;
  right: 1%;
  z-index: 99;
  .widget-data {
    padding: 10px;
    box-shadow: 1px 1px 5px 6px rgba(0, 0, 0, 0.03);
    border-radius: 5px;
    position: relative;
    transform: scale(0);
    opacity: 0;
    background: white;
    z-index: 99;
    transform-origin: top right;
    transition: 0.5s all ease;
    &.show {
      transform: scale(1);
      opacity: 1;
    }
  }
  > div {
    display: flex;
    justify-content: flex-end;
    .anime-icon {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: linear-gradient(45deg, #e66465, #e6191a, #0c2da6d1, #9198e5);
      cursor: pointer;
      transition: all 0.3s ease;
      animation: sirilike 2.5s linear;
      &:hover {
        transform: scale(1.15);
      }
    }
  }
}

@keyframes sirilike {
  0% {
    background: linear-gradient(45deg, #e66465, #e6191a, #0c2da6d1, #9198e5);
    transform: scale(1.5);
  }
  5% {
    background: linear-gradient(0.25turn, #3f87a6, #ebf8e1, #f69d3c);
  }
  10% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 70.71%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 70.71%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 70.71%);
  }
  15% {
    background: linear-gradient(0.25turn, #3f87a6, #ebf8e1, #f69d3c);
  }
  20% {
    background: linear-gradient(45deg, #e66465, #e6191a, #0c2da6d1, #9198e5);
    transform: scale(1);
  }
  25% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 70.71%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 70.71%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 70.71%);
  }
  30% {
    background: linear-gradient(0.25turn, #3f87a6, #ebf8e1, #f69d3c);
  }
  35% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 70.71%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 70.71%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 70.71%);
  }
  40% {
    background: linear-gradient(0.25turn, #3f87a6, #ebf8e1, #f69d3c);
    transform: scale(1.5);
  }
  45% {
    background: linear-gradient(45deg, #e66465, #e6191a, #0c2da6d1, #9198e5);
  }
  50% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 70.71%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 70.71%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 70.71%);
  }
  55% {
    background: linear-gradient(0.25turn, #3f87a6, #ebf8e1, #f69d3c);
  }
  60% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 70.71%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 70.71%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 70.71%);
    transform: scale(1);
  }
  65% {
    background: linear-gradient(0.25turn, #3f87a6, #ebf8e1, #f69d3c);
  }
  70% {
    background: linear-gradient(45deg, #e66465, #e6191a, #0c2da6d1, #9198e5);
  }
  75% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 70.71%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 70.71%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 70.71%);
  }
  80% {
    background: linear-gradient(0.25turn, #3f87a6, #ebf8e1, #f69d3c);
    transform: scale(1.5);
  }
  85% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 70.71%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 70.71%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 70.71%);
  }
  90% {
    background: linear-gradient(0.25turn, #3f87a6, #ebf8e1, #f69d3c);
  }
  95% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 70.71%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 70.71%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 70.71%);
  }
  100% {
    background: linear-gradient(45deg, #e66465, #e6191a, #0c2da6d1, #9198e5);
  }
}
</style>
