import Api from './Api';
import FunctionApi from './FunctionApi';

const endPoint = 'form';

const getForms = () => Api.get(`${endPoint}/getforms`);
const getFormNames = () => Api.get(`${endPoint}/getformnames`);
const getForm = (formId) => Api.get(`${endPoint}/getform`, {
  params: {
    formId,
  },
});
const saveFormDetails = (formDetails) => Api.post(`${endPoint}/saveformdetails`, formDetails);
// const getData = (fieldType, fieldValue) => FunctionApi.get(`getWidgetData?fieldType=${fieldType}&fieldValue=${fieldValue}`);
const getData = (input) => FunctionApi.post('getWidgetData', input);

export {
  getForms, getForm, getFormNames, saveFormDetails, getData,
};
