import Api from './Api';

const endPoint = 'submission';

const getSubmissions = (input) => Api.post(`${endPoint}/getsubmissions`, input);
const saveFormSubmission = (submissionData) => Api.post(`${endPoint}/savesubmissiondata`, submissionData);
const shareSubmission = (shareDetails) => Api.post(`${endPoint}/sharesubmission`, shareDetails);
const getSubmission = (submissionId) => Api.get(`${endPoint}/getsubmission`, {
  params: {
    submissionId,
  },
});
const approveOrReject = (submissionData) => Api.post(`${endPoint}/approveorreject`, submissionData);

const saveDraft = (draftData) => Api.post(`${endPoint}/savedraft`, draftData);

const deleteDraft = (submissionId) => Api.delete(`${endPoint}/deletedraft/${submissionId}`);

export {
  getSubmissions,
  saveFormSubmission,
  shareSubmission,
  getSubmission,
  approveOrReject,
  saveDraft,
  deleteDraft,
};
