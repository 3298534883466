import Api from './Api';

const endPoint = 'submissionhistory';

const getSubmissionHistory = (submissionId) => Api.get(`${endPoint}/getSubmissionHistory`, {
  params: {
    submissionId,
  },
});

export {
  getSubmissionHistory,
};
