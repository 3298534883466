import Vue from 'vue';
import { parseISO, format } from 'date-fns';
import './styles.scss';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import PrimeVue from 'primevue/config';
import VueApexCharts from 'vue-apexcharts';
import { debounce } from 'lodash';
// prime vue components
import Button from 'primevue/button';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import Tooltip from 'primevue/tooltip';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import Textarea from 'primevue/textarea';
import AutoComplete from 'primevue/autocomplete';
import Message from 'primevue/message';
import Carousel from 'primevue/carousel';

import 'primevue/resources/themes/nova/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import './assets/layout/layout.scss';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faFax } from '@fortawesome/free-solid-svg-icons';
import App from './App.vue';

import DataWidget from './components/DataWidget.vue';
import LoadingArete from './components/LoadingArete';
import store from './store/index';
import router from './router/index';

library.add(faFax);

Vue.config.productionTip = false;
Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.use(VueApexCharts);
Vue.use(PrimeVue);
Vue.use(ToastService);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

Vue.directive('tooltip', Tooltip);
Vue.component('LoadingArete', LoadingArete);
Vue.component('Button', Button);
Vue.component('Toast', Toast);
Vue.component('DataTable', DataTable);
Vue.component('Column', Column);
Vue.component('ColumnGroup', ColumnGroup);
Vue.component('Dialog', Dialog);
Vue.component('InputText', InputText);
Vue.component('Dropdown', Dropdown);
Vue.component('Dialog', Dialog);
Vue.component('Textarea', Textarea);
Vue.component('AutoComplete', AutoComplete);
Vue.component('apexchart', VueApexCharts);
Vue.component('Message', Message);
Vue.component('Carousel', Carousel);
Vue.component('DataWidget', DataWidget);

Vue.filter('formatDateTime', (value) => {
  if (value) {
    try {
      return format(parseISO(value), 'MM/dd/yy hh:mm a');
    } catch (err) {
      return format(new Date(value), 'MM/dd/yy hh:mm a');
    }
  }
});

Vue.directive('widget', {
  update: debounce(($el, binding) => {
    const data = { fieldType: binding.value.field_type, fieldValue: $el.value };
    store.dispatch('getWidgetData', data);
  }, 500),
});

Vue.mixin({
  methods: {
    getWidgetData: debounce(async (data, callbackfunc) => {
      await store.dispatch('form/getWidgetData', data);
      callbackfunc();
    }, 500),
  },
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
