import filter from 'lodash/filter';
import uniqBy from 'lodash/uniqBy';
import { getForms, saveFormDetails } from '../../services/formService';
import { getFormsList } from '../../services/formServerService';
import { getMavenlinkFields } from '../../services/mavenbridgeService';
import { getHubSpotFields } from '../../services/hubSpotGatewayService';

const getters = {
  getForms(state) {
    return state.forms;
  },
  getFormsList(state) {
    return state.formsList;
  },
  getFormSchema: (state) => (formId) => {
    const formSchema = filter(state.formsList, { formId });
    return formSchema.length > 0 ? formSchema[0].schema : [];
  },
  getMavenlinkFields(state) {
    return state.mavenlinkFields;
  },
  getHubSpotFields(state) {
    return state.hubSpotFields;
  },
};

const actions = {
  async getForms({ commit }) {
    const response = await getForms();
    commit('setForms', response.data.data);
  },
  async getFormsList({ commit }) {
    const response = await getFormsList();
    commit('setFormsList', response.data?.data?.data);
  },
  async getMavenlinkFields({ commit }) {
    const response = await getMavenlinkFields();
    let mavenlinkFields = [];
    const { standardFields } = response.data.data.data;
    standardFields.forEach((sf) => {
      mavenlinkFields.push({
        id: sf.title, name: sf.title, type: sf.type, isCustomField: false,
      });
      if (sf.properties) {
        sf.properties.forEach((sfp) => {
          mavenlinkFields.push({
            id: sfp.title, name: sfp.title, type: sfp.type, isCustomField: false,
          });
        });
      }
    });
    const { customFields } = response.data.data.data;
    customFields.forEach((cf) => {
      mavenlinkFields.push({
        id: cf.id.toString(), name: cf.name, type: cf.value_type, isCustomField: true,
      });
    });
    // filter duplicates and store
    mavenlinkFields = uniqBy(mavenlinkFields, 'name');
    commit('setMavenlinkFields', mavenlinkFields);
  },
  // eslint-disable-next-line no-unused-vars
  async saveFormDetails({ commit }, formDetails) {
    await saveFormDetails(formDetails);
  },
  async getHubSpotFields({ commit }) {
    const response = await getHubSpotFields();
    commit('setHubSpotFields', response.data.data);
  },
};

const mutations = {
  setForms(state, response) {
    state.forms = response;
  },
  setFormsList(state, response) {
    state.formsList = response;
  },
  setMavenlinkFields(state, response) {
    state.mavenlinkFields = response;
  },
  setHubSpotFields(state, response) {
    state.hubSpotFields = response;
  },
};

const state = {
  forms: {},
  formsList: {},
  formJson: {},
  mavenlinkFields: {},
  hubSpotFields: {},
};

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
