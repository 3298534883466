import {
  getUpdateFormSubmission,
  getUpdateFormSubmissionCount,
  saveUpdateFormSubmissionData,
} from '../../services/updateFormSubmissionService';
import { updateWorkspace } from '../../services/mavenbridgeService';

const getters = {
  getSubmissionDataCount: (state) => state.updateFormSubmissionCount,
  getSubmissionData: (state) => state.updateFormSubmission.data,
  getSubmissionModifiedDate: (state) => state.updateFormSubmission.modifiedOn,
  // TODO: Change this condition to check if fileInfo is available then it's published
  // TODO: state.updateFormSubmission.fileInfo != null
  isPublishedToMavenLink: (state) => state.updateFormSubmission.fileInfo == null,
  getUpdateFormSchema: (state) => state.updateFormSubmission.formSchema,
  getUpdateFormSubmissionId: (state) => state.updateFormSubmission.id,
  getMavenlinkProjectId: (state) => state.updateFormSubmission.mavenlinkProjectId,
  getUpdateFormSubmissionStatus: (state) => state.updateFormSubmission.status,
};

const actions = {
  async getUpdateFormSubmissionCount({ commit }, mavenlinkProjectId) {
    const response = await getUpdateFormSubmissionCount(mavenlinkProjectId);
    commit('setUpdateFormSubmissionCount', response.data.data);
  },
  async getUpdateFormSubmission({ commit }, submissionData) {
    const response = await getUpdateFormSubmission(submissionData);
    commit('setUpdateFormSubmissionData', response.data.data);
  },
  async saveFormSubmission({ commit }, submissionData) {
    const response = await saveUpdateFormSubmissionData(submissionData);
    commit('setUpdateFormSubmissionData', response.data.data);
  },
  // eslint-disable-next-line no-unused-vars
  async updateWorkspace({ commit }, submissionData) {
    return updateWorkspace(submissionData);
  },
};

const mutations = {
  setUpdateFormSubmissionData(state, response) {
    state.updateFormSubmission = response;
  },
  setUpdateFormSubmissionCount(state, response) {
    state.updateFormSubmissionCount = response;
  },
};

const state = {
  updateFormSubmission: {},
  updateFormSubmissionCount: null,
};

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
