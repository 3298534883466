import Api from './Api';

const endPoint = 'updateformsubmission';

const getUpdateFormSubmissions = () => Api.get(`${endPoint}/getupdateformsubmissions`);
const getUpdateFormSubmission = (submissionData) => Api.post(`${endPoint}/getupdateformsubmission`, submissionData);
const getUpdateFormSubmissionCount = (mavenlinkProjectId) => Api.get(`${endPoint}/getUpdateformSubmissioncount`, {
  params: {
    mavenlinkProjectId,
  },
});
const saveUpdateFormSubmissionData = (submissionData) => Api.post(`${endPoint}/saveupdateformsubmissiondata`, submissionData);

export {
  getUpdateFormSubmissions,
  getUpdateFormSubmission,
  getUpdateFormSubmissionCount,
  saveUpdateFormSubmissionData,
};
