import Vue from 'vue';
import Vuex from 'vuex';
import LoginModule from './modules/login.module';
import DashboardModule from './modules/dashboard.module';
import AdminModule from './modules/admin.module';
import FormModule from './modules/form.module';
import SettingsModule from './modules/settings.module';
import UpdateFormModule from './modules/updateform.module';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    login: LoginModule,
    dashboard: DashboardModule,
    admin: AdminModule,
    form: FormModule,
    settings: SettingsModule,
    updateForm: UpdateFormModule,
  },
});
