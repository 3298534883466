import router from '@/router';
import { isAdminUser } from '../../services/customloginService';
import { getUserProfilePhoto } from '../../services/graphService';

const getters = {
  getToken: (state) => state.user.token,
  getUser: (state) => state.user,
  getUsername: (state) => state.user.name,
  getUserEmail: (state) => state.user.email,
  getIsUserAuthenticated: (state) => state.user && !!state.user.token,
  getIsAdmin: (state) => state.isAdmin,
  getUserInitials: (state) => {
    let name = '';
    if (state.user.name && state.user.name.trim()) {
      name = state.user.name;
    } else if (state.user.email) {
      name = state.user.email;
    }
    const names = name.split(' ');
    let initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  },
};

const msalConfig = {
  auth: {
    clientId: process.env.VUE_APP_clientId,
    authority: process.env.VUE_APP_tenant,
    redirectUri: process.env.VUE_APP_redirectUri,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
};

const msalInstance = new window.Msal.UserAgentApplication(msalConfig);

const updateStore = (commit, response) => {
  const userDetails = {
    token: response.accessToken,
    name: response.account.name,
    email: response.account.userName,
  };
  commit('setUser', userDetails);
  localStorage.setItem('user', JSON.stringify(userDetails));
};

const loginRequest = {
  scopes: process.env.VUE_APP_scopes.split(','),
};

const actions = {
  login({ commit }) {
    return new Promise((resolve, reject) => {
      msalInstance
        .loginPopup(loginRequest)
        .then(() => {
          msalInstance
            .acquireTokenSilent(loginRequest)
            .then((tokenResponse) => {
              updateStore(commit, tokenResponse);
              resolve();
            })
            .catch((error) => {
              if (error.name === 'InteractionRequiredAuthError') {
                msalInstance
                  .acquireTokenPopup(loginRequest)
                  .then((tokenResponse) => {
                    updateStore(commit, tokenResponse);
                    resolve();
                  })
                  .catch((err) => {
                    reject(err);
                  });
              } else {
                reject(error);
              }
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  logout({ commit }) {
    commit('logout');
    localStorage.removeItem('user');
    router.push({ name: 'login' });
  },
  async isAdmin({ commit }) {
    const response = await isAdminUser();
    commit('setAdmin', response.data.data);
  },
  async getUserProfilePhoto() {
    const response = await getUserProfilePhoto();
    return response?.data?.data;
  },
  acquireTokenSilent({ commit, dispatch/* , state */ }) {
    return new Promise((resolve) => {
      msalInstance
        .acquireTokenSilent(loginRequest)
        .then((tokenResponse) => {
          const token = tokenResponse.accessToken;
          commit('setToken', token);
          const userDetails = JSON.parse(localStorage.getItem('user'));
          userDetails.token = token;
          localStorage.setItem('user', JSON.stringify(userDetails));
          resolve(token);
        })
        .catch((error) => {
          if (error && error.name === 'InteractionRequiredAuthError') {
            return msalInstance
              .acquireTokenPopup(loginRequest)
              .then((tokenResponse) => {
                const token = tokenResponse.accessToken;
                commit('setToken', token);
                const userDetails = JSON.parse(localStorage.getItem('user'));
                userDetails.token = token;
                localStorage.setItem('user', JSON.stringify(userDetails));
                resolve(token);
              })
              .catch(() => {
              });
          }
          if (error && error.name === 'ClientAuthError') {
            dispatch('logout');
          }
        });
    });
  },
};

const mutations = {
  setUser(state, user) {
    state.user = user;
  },
  setToken(state, token) {
    state.user.token = token;
  },
  setAdmin(state, isAdmin) {
    state.isAdmin = isAdmin;
  },
  logout(state) {
    state.user = {};
  },
};

const state = {
  user: JSON.parse(localStorage.getItem('user')) || {},
  isAdmin: null,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
