// Customizing the axios api calls
import axios from 'axios';
import store from '../store/index';
import router from '../router/index';

const Api = axios.create({
  baseURL: process.env.VUE_APP_baseUrl,
  // timeout:5000, //5 seconds
});

Api.interceptors.request.use(
  async (config) => {
    const token = await store.dispatch('login/acquireTokenSilent');
    // const token = store.getters['login/getToken'];
    // for every request add the token to the authorization header
    config.headers.Authorization = `${token}`;
    return config;
  },
  (error) => Promise.reject(error),
);

Api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response) {
      switch (error.response.status) {
        case 401: {
          const token = await store.dispatch('login/acquireTokenSilent');
          if (token) {
            originalRequest.headers.Authorization = token;
            return new Promise((resolve, reject) => {
              axios(originalRequest)
                .then((res) => {
                  resolve(res);
                })
                .catch((err) => {
                  reject(err);
                });
            });
          }
          store.dispatch('login/logout');

          break;
        }
        case 403: router.push('/error');
          break;
        default: break;
      }
    }
  },
);

export default Api;
