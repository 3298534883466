import Api from './Api';

const endPoint = 'formserver';

const getFormsList = () => Api.get(`${endPoint}/getForms`);
const getFormSchema = (formServerFormId) => Api.get(`${endPoint}/getForm`, {
  params: {
    formId: formServerFormId,
  },
});

export {
  getFormsList,
  getFormSchema,
};
