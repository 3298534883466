<template>
    <div>
        <div v-if="showPanel" class="card widget-panel">
            <div v-if="showSliderWidget">
                <Carousel :value="widgetData"
                          :numVisible="1"
                          :numScroll="1"
                          :responsiveOptions="responsiveOptions"
                          class="custom-carousel">
                    <template #header>
                        <div class="pull-right p-col-3">
                            <div class="pull-left"
                                 @click="togglewidgets()"
                                 v-tooltip.bottom="'Click to view as List'"
                                 @mouseover="hover = true"
                                 style="cursor: pointer">
                                <span class="pi pi-list pull-right"
                                      style="margin-left: 45px"></span>
                            </div>
                            <div class="pull-right"
                                 @click="closePanel()"
                                 v-tooltip.left="'Click to close'"
                                 style="cursor: pointer">
                                <span class="pi pi-times pull-right" style="color: grey"></span>
                            </div>
                        </div>
                        <span class="widget-header"> Data Statistics</span>
                    </template>
                    <template #item="slotProps">
                        <div class="product-item">
                            <div class="text-center">
                                <div class="p-mb-3"></div>

                                <div>
                                    <div class="p-mb-1 h5-styles">
                                        {{ slotProps.data.Key }}
                                    </div>
                                    <div class="h3-styles">
                                        {{ slotProps.data.Value }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </Carousel>
            </div>
            <div v-if="showValueCount > 0 && isWidgetDataLoading">
                <h6 class="text-center" style="color: yellow">{{ widgetTxt }}</h6>
            </div>
        </div>

        <div v-if="showPanel && !showSliderWidget"
             class="widget-data-list card widget-panel">
            <div v-if="showValueCount == 0 && isWidgetDataLoading"
                 class="card widget-panel"
                 style="min-height: 23%">
                <div @click="closePanel()" style="cursor: pointer">
                    <span class="pi pi-times pull-right" style="color: grey"></span>
                </div>
                <h6 class="text-center" style="margin-top: 40px; color: yellow">
                    {{ widgetTxt }}
                </h6>
            </div>
            <div v-else>
                <div class="pull-right p-col-3">
                    <div class="pull-left"
                         @click="togglewidgets()"
                         v-tooltip.bottom="'Click to view as Slide'"
                         style="cursor: pointer">
                        <span class="pi pi-sliders-h pull-right"
                              style="color: yellow; margin-left: 45px"></span>
                    </div>
                    <div class="pull-right"
                         @click="closePanel()"
                         v-tooltip.left="'Click to close'"
                         style="cursor: pointer">
                        <span class="pi pi-times pull-right" style="color: grey"></span>
                    </div>
                </div>
                <span class="widget-header"> Data Statistics</span>
                <ul class="widget-data-ul">
                    <li v-for="(item, index) in widgetData" :key="index">
                        <div class="h5-styles">{{ item.Key }}</div>
                        <div class="h3-styles">{{ item.Value }}</div>
                    </li>
                </ul>
            </div>
            <div v-if="showValueCount > 0 && isWidgetDataLoading">
                <h6 class="text-center" style="color: yellow">{{ widgetTxt }}</h6>
            </div>
        </div>
        <div v-if="showValueCount == 0 && isWidgetDataLoading"
             class="card widget-panel"
             style="min-height: 23%">
            <div @click="closePanel()" style="cursor: pointer">
                <span class="pi pi-times pull-right" style="color: grey"></span>
            </div>
            <h6 class="text-center" style="margin-top: 40px; color: yellow">
                {{ widgetTxt }}
            </h6>
        </div>
    </div>
</template>
<script>
export default {
  props: {},
  data() {
    return {
      filters: [],
      widgetData: null,
      showPanel: false,
      showSliderWidget: true,
      isWidgetDataLoading: false,
      widgetTxt: 'Loading Widget Data...',
      showValueCount: 0,
      isWidgetDataNull: false,
      hasEverClickedClose: false,
    };
  },
  created() {
    this.showValueCount = 0;
  },
  mounted() {
    this.showValueCount = 0;
  },
  methods: {
    onDataValueChanged(attributes, fieldValue) {
      if (attributes['data-enablewidget'] === 'true') {
        const input = {
          fieldType: attributes['data-widgetfield'],
          fieldValue,
        };
        const objIndex = this.filters.findIndex(
          (obj) => obj.fieldType === input.fieldType,
        );
        if (objIndex < 0) {
          this.filters.push(input);
        } else if (fieldValue === '' || fieldValue == null) {
          this.filters.splice(objIndex, 1);
        } else {
          this.filters[objIndex].fieldValue = input.fieldValue;
        }
        this.showValueCount = this.hasEverClickedClose
          ? 0
          : this.showValueCount;
        this.widgetTxt = 'Loading Widget data...';
        this.showPanel = true;
        this.isWidgetDataLoading = true;
        if (this.filters.length > 0) {
          this.getWidgetData(this.filters, () => {
            const result = this.$store.getters['form/getWidgetData'];
            if (result?.Value.length === 0) {
              this.isWidgetDataNull = true;
              this.showValueCount = 0;
              this.widgetTxt = 'No Widget Data Found.';
            }
            this.widgetData = result?.Value;
            this.isWidgetDataLoading = false;
            this.hasEverClickedClose = false;
            this.showValueCount += 1;
          });
        } else {
          this.showValueCount = 0;
          this.isWidgetDataLoading = false;
          this.showPanel = false;
          this.widgetData = null;
        }
      }
    },
    refreshDataWidgets(filterValues) {
      if (JSON.stringify(this.filters) !== JSON.stringify(filterValues)) {
        this.filters = filterValues;
        this.showValueCount = this.hasEverClickedClose
          ? 0
          : this.showValueCount;

        if (filterValues.length > 0) {
          this.widgetTxt = 'Loading Widget data...';
          this.showPanel = true;
          this.isWidgetDataLoading = true;
          this.getWidgetData(filterValues, () => {
            const result = this.$store.getters['form/getWidgetData'];
            if (result?.Value.length === 0) {
              this.isWidgetDataNull = true;
              this.showValueCount = 0;
              this.widgetTxt = 'No Widget Data Found.';
            }
            this.widgetData = result?.Value;
            this.isWidgetDataLoading = false;
            this.hasEverClickedClose = false;
            this.showValueCount += 1;
          });
        }
      }
    },
    getWidgetFilters() {
      return this.filters;
    },
    closePanel() {
      this.showPanel = false;
      this.showValueCount = 0;
      this.isWidgetDataNull = false;
      this.hasEverClickedClose = true;
    },
    togglewidgets() {
      this.showSliderWidget = !this.showSliderWidget;
    },
  },
};
</script>

<style scoped>
    .toolbar {
        box-sizing: border-box;
    }

    .widget-panel {
        position: fixed;
        padding: 9px;
        top: 51px;
        right: 2px;
        width: 30%;
        z-index: 999;
        box-shadow: -2px 3px 7px 2px lightgrey;
        background-color: #224faf;
        color: white;
        /* #DDDFF6; */
        /* #224FAF */
    }

    .close:hover {
        cursor: pointer;
    }

    .widget-header {
        font-family: "Open Sans", "Helvetica Neue", sans-serif !important;
        font-size: 18px !important;
        text-align: center !important;
        font-weight: bold !important;
        color: yellow;
        margin-bottom: 15px;
    }

    ul.widget-data-ul {
        list-style-type: square;
        padding-top: 3x;
        margin-top: 15px;
    }

        ul.widget-data-ul li {
            margin-bottom: 1em;
        }

    .h5-styles {
        font-family: "Open Sans", "Helvetica Neue", sans-serif !important;
        font-size: 1.25rem;
        font-weight: 500;
        line-height: 1.2;
    }

    .h3-styles {
        font-family: "Open Sans", "Helvetica Neue", sans-serif !important;
        font-size: 1.75rem;
        font-weight: 500;
        line-height: 1.2;
    }
</style>
