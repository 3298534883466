import Api from './Api';

const endPoint = 'settings';

const getSettings = () => Api.get(`${endPoint}/getsettings`);
const getSetting = (settingName) => Api.get(`${endPoint}/getsetting`, {
  params: {
    settingName,
  },
});
const saveSettings = (settings) => Api.post(`${endPoint}/savesettings`, settings);

export {
  getSettings,
  getSetting,
  saveSettings,
};
