<template>
    <div>
        <div class="login-body">
            <div v-if="loading">
                <LoginLoadingArete />
            </div>
            <div class="login-panel"></div>
            <div class="body-container">
                <div class="p-grid">
                    <div class="p-col-12 p-lg-6 left-side">
                    </div>
                    <div class="p-col-12 p-lg-6 right-side">
                        <div class="login-wrapper">
                            <div class="login-container">
                                <div class="p-d-flex p-flex-column p-fluid p-ai-center card p-shadow-3">
                                    <div class="p-mb-3 p-mt-5 header ">
                                        <font-awesome-icon :icon="['fas', 'fax']" size="3x" :style="{ color: '#135287' }" />
                                        <h1>
                                            Welcome to Scoping Call
                                        </h1>
                                    </div>
                                    <div class="p-mb-5" style="width:27rem">
                                        <Button class="p-button-lg p-button-raised" style="margin: 16px 0 8px"
                                            @click="login">
                                            <img src="../assets/layout/images/arete-icon-transparent.svg" class="avatar" alt="arete" />
                                            <div class="button-text">LOG IN WITH YOUR ARETE ACCOUNT</div>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
const LoginLoadingArete = () => import('@/components/LoginLoadingArete');

export default {
  name: 'Login',
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async login() {
      this.loading = true;
      await this.$store.dispatch('login/login');
      this.$router.replace({ name: 'home' }).catch(() => { });
    },
  },
  components: {
    LoginLoadingArete,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.avatar {
    width: 30px;
    height: 30px;
    vertical-align: middle;
    border-radius: 50%;
    margin: 0.5em;
}

.login-body {
    height: 100vh;
    font-size: 16px;
    margin: 0;
    background-position: top right;
    background-repeat: no-repeat;
    font-family: 'Poppins', sans-serif;
}

.login-body .body-container {
    position: fixed;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    text-align: center;
    z-index: 3;
}

.login-body .body-container .p-grid {
    margin-right: 0;
}

.login-body .right-side {
    width: 50%;
}

.login-body .left-side {
    height: 100%;
    width: 50%;
}

.login-wrapper {
    text-align: center;
    width: 424px;
    position: relative;
    left: 10%;
}

.login-wrapper .login-container {
    box-sizing: border-box;
    text-align: center;
    color: #4f575b;
}

.login-wrapper .login-container .p-button {
    width: 100%;
    font-size: 16px;
}

.login-wrapper .login-container .button-text {
    font-weight: bold;
    font-size: 15px;
}

@media (max-width: 40em) {
    .login-body .right-side {
        height: calc(100% - 250px);
        width: 100%;
    }
}

@media (max-width: 64em) {
    .login-body {
        text-align: center;
        background-size: cover;
    }

    .login-body .right-side {
        height: 100%;
        width: 100%;
    }

    .login-wrapper {
        width: 400px;
        margin: 0 auto;
    }
}

@media screen and (min-width: 768px) {
    .login-body {
        background-image: url("../assets/layout/images/arete-background.jpg");
        background-size: cover;
        background-attachment: fixed;
    }

    .login-body .right-side {
        display: flex;
        align-items: center;
        height: 100vh;
    }

    .login-body .body-container {
        padding: 20px 0 0 80px;
    }

    .login-body .login-panel {
        padding: 80px;
        display: block;
        position: fixed;
        background-color: #f3f5f6;
        height: 200%;
        width: 100%;
        right: -60%;
        top: -50%;
        filter: alpha(opacity=95);
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        z-index: 3;
    }
}
</style>
