import Api from './Api';

const endPoint = 'hubspot';

const getHubSpotFields = () => Api.get(`${endPoint}/gethubspotfields`);
const syncProperties = () => Api.post(`${endPoint}/syncproperties`);

export {
  getHubSpotFields,
  syncProperties,
};
