<template>
  <div class="widget">
    <apexchart
      width="500"
      type="bar"
      :options="options"
      :series="series"
    ></apexchart>
  </div>
</template>
<script type="text/javascript">
export default {
  props: ['series', 'options'],
};
</script>

<style scoped>
.widget {
  display: flex;
  justify-content: center;
}
</style>
