import Api from './Api';

const endPoint = 'notification';

const getNotifications = (submissionId) => Api.get(`${endPoint}/getnotifications`, {
  params: {
    submissionId,
  },
});

export {
  getNotifications,
};
