import { getUsers } from '../../services/dashboardService';
import { getFormNames } from '../../services/formService';
import {
  getSubmissions,
  shareSubmission,
  deleteDraft,
} from '../../services/submissionService';
import { getSubmissionHistory } from '../../services/submissionHistoryService';
import { getNotifications } from '../../services/notificationService';

const getters = {
  getUsers(state) {
    return state.users;
  },
  getFormNames(state) {
    return state.formNames;
  },
  getSubmissions(state) {
    return state.submissions;
  },
  getSubmissionHistory(state) {
    return state.submissionHistory;
  },
  getNotifications(state) {
    return state.notifications;
  },
};

const actions = {
  async getSubmissions({ commit }, paginatedInput) {
    const response = await getSubmissions(paginatedInput);
    const submissions = response.data.data.result;
    if (submissions && submissions.length) {
      submissions.forEach((submission) => {
        submission.clientName = submission.data.data.clientName;
      });
    }
    commit('setSubmissions', submissions);
    return response.data.data;
  },
  async getSubmissionHistory({ commit }, submissionId) {
    const response = await getSubmissionHistory(submissionId);
    commit('setSubmissionHistory', response.data.data);
  },
  async getUsers({ commit }, user) {
    const response = await getUsers(user);
    commit('setUsers', response.data.data);
  },
  async getFormNames({ commit }) {
    const response = await getFormNames();
    commit('setFormNames', response.data.data);
  },
  // eslint-disable-next-line no-unused-vars
  async shareSubmission({ commit }, shareDetails) {
    await shareSubmission(shareDetails);
  },
  async getNotifications({ commit }, submissionId) {
    const response = await getNotifications(submissionId);
    commit('setNotifications', response.data.data);
  },
  // eslint-disable-next-line no-unused-vars
  async deleteDraft({ commit }, submissionId) {
    const response = await deleteDraft(submissionId);
    return response.data.data;
  },
};

const mutations = {
  setSubmissions(state, response) {
    state.submissions = response;
  },
  setSubmissionHistory(state, response) {
    state.submissionHistory = response;
  },
  setUsers(state, response) {
    state.users = response;
  },
  setFormNames(state, response) {
    state.formNames = response;
  },
  setNotifications(state, response) {
    state.notifications = response;
  },
};

const state = {
  users: {},
  formNames: {},
  submissions: {},
  submissionHistory: {},
  notifications: {},
};

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
