import Api from './Api';

const endPoint = 'mavenlink';

const getMavenlinkFields = () => Api.get(`${endPoint}/getmavenlinkfields`);
const publish = (submissionData) => Api.post(`${endPoint}/createproject`, submissionData);
const syncChoices = () => Api.post(`${endPoint}/syncchoices`);
const updateWorkspace = (submissionData) => Api.post(`${endPoint}/updateproject`, submissionData);

export {
  getMavenlinkFields,
  publish,
  syncChoices,
  updateWorkspace,
};
