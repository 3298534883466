<template>
  <div class="widget">
    <div class="ratetag" v-for="key in Object.keys(data)" :key="key">
      {{ key }} - {{ data[key] }}
    </div>
  </div>
</template>
<script type=" text/javascript">
export default {
  name: 'TagChart',
  data() {
    return {
      data: {
        projects: 670,
        billable_entries: 38130,
        non_billable_entries: 13637,
        expenses: 141,
        prev_billing: 10753,
        unapproved_time_entries: 9,
      },
    };
  },
};
</script>
<style scoped lang="scss">
.ratetag {
  padding: 4px 6px 4px 6px;
  border-radius: 3px;
  background-color: #28a475;
  color: #fff;
  border-radius: 25px;
  -webkit-transition: all 1s;
  transition: all 1s;
  position: relative;
  margin-bottom: 3px;
  &:before {
    content: "\25CF";
    color: #fff;
    margin-right: 3px;
  }
}
</style>
