<template>
  <div id="app">
    <div v-if="show_widget">
      <widget />
    </div>
    <router-view />
    <Toast position="bottom-right" />
  </div>
</template>
<script>
import { mapState } from 'vuex';
import Widget from '@/components/widgets/Widget.vue';

export default {
  name: 'app',
  components: { Widget },
  computed: {
    ...mapState({
      show_widget: (state) => state.form.showWidget,
    }),
  },
};
</script>
<style lang="scss">
@import "./App.scss";
</style>
