import { getForm, getData } from '../../services/formService';
import { getFormSchema } from '../../services/formServerService';
import {
  saveFormSubmission, getSubmission, approveOrReject, saveDraft,
} from '../../services/submissionService';
import { publish } from '../../services/mavenbridgeService';

const getters = {
  getWidgetData(state) {
    return state.widgetData;
  },
  getFormServerFormId(state) {
    return state.form.formServerFormId;
  },
  getUpdateFormId(state) {
    return state.form.updateFormId;
  },
  getSubmissionData(state) {
    return state.submissionData.data;
  },
  getSubmission(state) {
    return state.submissionData;
  },
  getFormName(state) {
    return state.form.displayName;
  },
  getSubmissionStatus(state) {
    return state.submissionData.status;
  },
  getHasReviewers(state) {
    return state.form?.reviewers?.length || false;
  },
  getCanUserEdit: (state) => (user) => {
    if (state.submissionData) {
      if (state.submissionData.sharedWith) {
        const sharedDetails = state.submissionData.sharedWith.selectedPermissions;
        const sharedUsers = state.submissionData.sharedWith.selectedUsers;
        const ifExists = sharedUsers.filter((sharedUser) => sharedUser.email === user && sharedDetails != null && sharedDetails.includes('edit'));
        return ifExists.length > 0;
      }
      if (state.form.reviewers && state.form.reviewers.length) {
        const { reviewers } = state.form;
        const ifExists = reviewers.filter((reviewer) => reviewer.email === user);
        return ifExists.length > 0;
      }
      if (state.form.publishers && state.form.publishers.length) {
        const { publishers } = state.form;
        const ifExists = publishers.filter((publisher) => publisher.email === user);
        return ifExists.length > 0;
      }
      return false;
    }
    return false;
  },
  getCanUserApproveOrReject: (state) => (user) => {
    if (state.submissionData) {
      if (state.submissionData.sharedWith) {
        const sharedDetails = state.submissionData.sharedWith.selectedPermissions;
        const sharedUsers = state.submissionData.sharedWith.selectedUsers;
        const ifExists = sharedUsers.filter((sharedUser) => sharedUser.email === user && sharedDetails != null && sharedDetails.includes('approve'));
        if (ifExists.length > 0) return true;
      }
      if (state.form.reviewers && state.form.reviewers.length) {
        const { reviewers } = state.form;
        const ifExists = reviewers.filter((reviewer) => reviewer.email === user);
        return ifExists.length > 0;
      }
      return false;
    }
    return false;
  },
  getCanUserPublish: (state) => (user) => {
    if (state.submissionData) {
      if (state.submissionData.sharedWith) {
        const sharedDetails = state.submissionData.sharedWith.selectedPermissions;
        const sharedUsers = state.submissionData.sharedWith.selectedUsers;
        const ifExists = sharedUsers.filter((sharedUser) => sharedUser.email === user && sharedDetails != null && sharedDetails.includes('publish'));
        if (ifExists.length > 0) return true;
      }
      if (state.form.publishers && state.form.publishers.length) {
        const { publishers } = state.form;
        const ifExists = publishers.filter((publisher) => publisher.email === user);
        return ifExists.length > 0;
      }
      return false;
    }
    return false;
  },
  getFormSchema: (state) => ({ components: state.submissionData.formSchema.components }),
};

const actions = {
  async getForm({ commit }, formId) {
    const response = await getForm(formId);
    commit('setForm', response.data.data);
  },
  // eslint-disable-next-line no-unused-vars
  async saveFormSubmission({ commit }, submissionData) {
    const response = await saveFormSubmission(submissionData);
    return response.data.data;
  },
  async getSubmission({ commit }, submissionId) {
    const response = await getSubmission(submissionId);
    commit('setSubmissionData', response.data.data);
  },
  // eslint-disable-next-line no-unused-vars
  async approveOrReject({ commit }, submissionData) {
    const response = await approveOrReject(submissionData);
    return response.data.data;
  },
  // eslint-disable-next-line no-unused-vars
  async publish({ commit }, submissionData) {
    const response = await publish(submissionData);
    return response.data;
  },
  // eslint-disable-next-line no-unused-vars
  async getFormSchema({ commit }, formServerFormId) {
    const response = await getFormSchema(formServerFormId);
    return response.data.data.data;
  },
  async getWidgetData({ commit }, data) {
    const response = await getData(data);
    commit('updateWidgetData', response.data);
  },
  // eslint-disable-next-line no-unused-vars
  async saveDraft({ commit }, submissionData) {
    const response = await saveDraft(submissionData);
    return response.data.data;
  },
};

const mutations = {
  setForm(state, response) {
    state.form = response;
  },
  setSubmissionData(state, response) {
    state.submissionData = response;
  },
  updateWidgetData(state, data) {
    state.widgetData = data;
  },
  showWidget(state, show) {
    state.showWidget = show;
  },
};

const state = {
  form: {},
  submissionData: {},
  showData: [],
  showWidget: false,
  widgetData: null,
};

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
