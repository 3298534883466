import Api from './Api';

const endPoint = 'users';

const getUsers = (user) => Api.get(`${endPoint}/getusers`, {
  params: {
    userFilter: user,
  },
});

export {
  getUsers,
};
